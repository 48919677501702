import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component:() => import('@/views/index')
    },
    {
      path: '/emailLogin',
      name: 'emailLogin',
      component:() => import('@/views/emailLogin')
    },
    {
      path: '/otp',
      name: 'otp',
      component:() => import('@/views/otp')
    },
     {
      path: '/success',
      name: 'success',
      component:() => import('@/views/success')
    },
  ]
})